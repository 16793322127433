/* eslint-disable no-trailing-spaces */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import {
    DTO, DTOMetaData, DTOMethods, DTOClassMethods,
    BoundEndpointAPI, Attachment
} from '../dto';

export interface Group_v1 extends DTO, DTOMethods<Group_v1> {
    resolvedUsers?: Attachment[];
}
export const Group_v1: DTOMetaData & DTOClassMethods<Group_v1> = {
    _label:'Groups',
    _dto: 'Group_v1',
    _type: 'group',
    _colors: { primaryThemeColor: "#c2ac19", secondaryThemeColor: "#c2ac19", tertiaryThemeColor: "#c2ac19" },
    _keysets: ["id"], // TODO: Generate from DTO Schema
    _properties: [
     ],
    _related: [
        { prop: 'resolvedUsers', type: 'unknown', class: 'Unknown', label: 'UsersWithFullyResolvedAccessLevel' },
        { prop: 'assignments', type: 'unknown', class: 'Unknown', label: 'Assignments' },
     ],
    _endpoint: '/api/eom/v1.0/group/v1.0',
    _docLink: '/wiki/elevate/Assets/group/',
    _icon: './assets/dtos/group.svg',
    endpointAPI: new BoundEndpointAPI<Group_v1>('Group_v1', DTO),
    from: (obj: any): Group_v1 => {
        return Object.setPrototypeOf(obj, Group_v1._prototype);
    },
    select: (p: any = ''): any => {
        return Group_v1.endpointAPI.get(p).then(res =>
            !res ? undefined
            : Array.isArray(res) ? res.map(a => Group_v1.from(a) as Group_v1)
            : Group_v1.from(res)
    )}
}
